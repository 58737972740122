#advantages {
  position: relative;
  padding-bottom: 175px;
  margin-bottom: 195px;
  .advantages__inner {
    @include flex(space-between, center);
    flex-direction: column;
  }
  .advantages__image-wrapper {
    width: 100%;
    height: 250px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .advantages__title,
  .advantages__list-item,
  .advantages__text {
    margin-bottom: 40px;
  }
  .advantages__item-title {
    margin-bottom: 12px;
  }
  .advantages__scrolling-bar:last-child {
    bottom: -75px;
  }

  @include phone {
    .advantages__image-wrapper {
      height: 350px;
    }
  }

  @include table {
    padding-bottom: 371px;
    margin-bottom: 418px;
    .advantages__scrolling-bar:last-child {
      bottom: -150px;
    }
  }

  @include desktop-sm {
    .advantages__inner {
      flex-direction: row;
    }
    .advantages__main {
      padding-right: 20px;
      width: 100%;
      max-width: 784px;
    }
    .advantages__image-wrapper {
      width: 920px;
      height: 866px;
    }
    .advantages__list-item {
      margin-top: 48px;
    }
    .advantages__item-title {
      margin-bottom: 16px;
    }
    .advantages__text {
      margin-top: 100px;
      width: 100%;
      max-width: 608px;
    }
  }

  @include desktop {
    padding-bottom: 567px;
    margin-bottom: 640px;
    .advantages__scrolling-bar:last-child {
      bottom: -240px;
    }
  }
}
