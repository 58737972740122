@keyframes zoomOut {
  to {
    transform: scale(1);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@keyframes scale100 {
  to {
    transform: scale(1);
  }
}

@keyframes animLineToCountry {
  0% {
    stroke-dasharray: 2200;
    stroke-dashoffset: 2200;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes rotate2d {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scrollTextNext {
  0% {
    transform: translate(-200%, 0);
  }

  100% {
    transform: translate(0%, 0);
  }
}

@keyframes scrollText {
  0% {
    transform: translate(-100%, 0);
  }

  100% {
    transform: translate(100%, 0);
  }
}

@keyframes reverseScrollTextNext {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-200%, 0);
  }
}

@keyframes reverseScrollText {
  0% {
    transform: translate(100%, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}
