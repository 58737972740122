#howWeWork {
  padding-bottom: 64px;
  .howWeWork__title {
    margin-bottom: 40px;
  }
  .howWeWork__list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .howWeWork__list-item {
    @include flex();
    padding: 25px 20px 25px 85px;
    border: 1px solid $primary-300;
    position: relative;
  }
  .howWeWork__item-num {
    color: $primary-100;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
  .howWeWork__item-main {
    text-align: center;
  }

  @include table {
    padding-bottom: 120px;
    .howWeWork__title {
      margin-bottom: 68px;
    }
    .howWeWork__list {
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }
  }

  @include desktop {
    padding-bottom: 240px;
    .howWeWork__list {
      grid-template-columns: repeat(3, 1fr);
    }
    .howWeWork__item {
      font-size: 20px;
      line-height: 36px;
    }
  }
}
