.footer {
  text-align: center;
  padding-bottom: 20px;
  &__links {
    margin-top: 40px;
  }
  &__link {
    margin-bottom: 40px;
    &-title {
      margin-bottom: 12px;
    }
    &-href {
      font-size: 12px;
      line-height: 24px;
    }
  }
  &__copy {
    font-size: 12px;
    line-height: 24px;
    color: $primary-950;
    opacity: 0.4 !important;
  }

  @include table {
    padding-bottom: 120px;
    .logo-full {
      &__img,
      &__img svg {
        width: 48px;
        height: 48px;
      }
      &__title {
        font-size: 26px;
        line-height: 40px;
      }
    }
    &__links {
      margin-top: 80px;
      @include flex(center, flex-start);
    }
    &__link {
      margin: 0 16px 80px;
      max-width: 452px;
      width: 100%;
      &-title {
        margin-bottom: 24px;
      }
    }
    &__link:nth-child(2) {
      max-width: 296px;
      width: 100%;
    }
    &__copy {
      font-size: 18px;
      line-height: 26px;
    }
  }

  @include desktop {
    .logo-full {
      &__img,
      &__img svg {
        width: 64px;
        height: 64px;
      }
      &__title {
        font-size: 28px;
        line-height: 44px;
      }
    }
  }
}
