#preview {
  padding: 80px 0 64px;
  .preview__title-wrapper,
  .preview__main-text {
    margin-bottom: 24px;
    width: 100%;
  }
  .preview__title-wrapper {
    position: relative;
    overflow: hidden;
    height: 75px;
  }
  .preview__title {
    position: absolute;
    bottom: -75px;
    left: 0;
    font-size: 39px;
    line-height: 39px;
    max-width: 300px;
    width: 100%;
  }
  .preview__info {
    @include flex(space-between, flex-start);
    flex-direction: column;
  }
  .preview__main,
  .preview__whyUs {
    width: 100%;
    position: relative;
  }
  .preview__sticker {
    position: absolute;
    top: -16px;
    left: 0;
    height: 64px;
    width: 66px;
    transform: scale(0);
    animation-name: scale100, rotate2d;
    animation-duration: 1s, 6s;
    animation-delay: 0.7s, 1.7s;
    animation-iteration-count: 1, infinite;
    animation-fill-mode: forwards, none;
    animation-timing-function: ease-in-out, linear;
    svg {
      height: 64px;
      width: 66px;
    }
  }
  .preview__images {
    position: relative;
    margin: 20px 0 55px;
    width: 100%;
  }
  .preview__image {
    width: 100%;
    height: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transform: scale(1.1);
    &-1 {
      animation: zoomOut 2s ease-in-out forwards 800ms;
      object-fit: contain;
    }
    &-2 {
      animation: zoomOut 2s ease-in-out forwards 1000ms;
      object-fit: cover;
    }
    &-wrapper {
      overflow: hidden;
    }
    &-wrapper-1 {
      position: absolute;
      z-index: 1;
      top: 110px;
      width: 100%;
      max-width: 270px;
      height: 125px;
    }
    &-wrapper-2 {
      margin-right: 0;
      margin-left: auto;
      width: 200px;
      height: 220px;
    }
  }
  .preview__images--mobile {
    display: block;
  }
  .preview__images--desktop {
    display: none;
  }
  .preview__whyUs {
    &-title-wrapper {
      margin-bottom: 16px;
      position: relative;
      overflow: hidden;
      height: 28px;
    }
    &-title {
      position: absolute;
      bottom: -28px;
      left: 0;
    }
  }

  @media screen and (min-width: 450px) {
    .preview__image {
      &-wrapper-1 {
        top: 86px;
        max-width: none;
        width: 370px;
        height: 175px;
      }
      &-wrapper-2 {
        width: 330px;
        height: 240px;
      }
    }
    .preview__title-wrapper {
      height: 100px;
    }
    .preview__title {
      bottom: -100px;
      font-size: 52px;
      line-height: 52px;
      max-width: 400px;
    }
  }

  @include phone {
    .preview__images {
      margin-bottom: 70px;
    }
    .preview__image {
      &-wrapper-1 {
        top: 126px;
        width: 470px;
        height: 275px;
      }
      &-wrapper-2 {
        width: 430px;
        height: 340px;
      }
    }
    .preview__title-wrapper {
      height: 10vw;
      margin-bottom: 48px;
    }
    .preview__title {
      bottom: -10vw;
      font-size: 7.5vw;
      line-height: 10vw;
      white-space: nowrap;
      max-width: fit-content;
    }
  }

  @include table {
    padding: 100px 0 136px;
    .preview__sticker,
    .preview__sticker svg {
      width: 86px;
      height: 84px;
    }
    .preview__images {
      margin-bottom: 90px;
    }
    .preview__image {
      &-wrapper-1 {
        top: 196px;
        width: 670px;
        height: 305px;
      }
      &-wrapper-2 {
        width: 530px;
        height: 440px;
      }
    }
    .preview__whyUs {
      &-title-wrapper {
        height: 38px;
      }
      &-title {
        bottom: -38px;
      }
    }
  }

  @include desktop-sm {
    .preview__image {
      &-wrapper-1 {
        top: 161px;
        width: 745px;
        height: 336px;
      }
      &-wrapper-2 {
        width: 700px;
        height: 440px;
      }
    }
  }
  @include desktop {
    padding: 120px 0 336px;
    .preview__info {
      flex-direction: row;
    }
    .preview__main,
    .preview__whyUs-info {
      max-width: 764px;
    }
    .preview__main {
      margin-right: 70px;
    }
    .preview__whyUs {
      max-width: 952px;
      &-title-wrapper {
        height: 48px;
      }
      &-title {
        bottom: -48px;
      }
    }
    .preview__whyUs-info {
      margin-bottom: 36px;
    }
    .preview__image-wrapper-1 {
      top: auto;
      width: 952px;
      height: 472px;
    }
    .preview__image-wrapper-2 {
      width: 100%;
      height: 540px;
    }
    .preview__images--mobile {
      display: none;
    }
    .preview__images--desktop {
      display: block;
    }
    .preview__sticker {
      left: auto;
      top: -50px;
      right: 0;
      width: 104px;
      height: 100px;
      svg {
        width: 104px;
        height: 100px;
      }
    }
  }

  @media screen and (min-width: 1500px) {
    .preview__sticker {
      top: 40px;
    }
    .preview__image-wrapper-1 {
      width: 1056px;
    }
  }

  @media screen and (min-width: 1700px) {
    .preview__title-wrapper {
      height: 128px;
    }
    .preview__title {
      bottom: -128px;
      font-size: 128px;
      line-height: 128px;
    }
  }
}
