// Sizes
$container: 1920px;
$xxl-min: 1400px;
$xl-min: 1200px;
$lg-min: 992px;
$md-min: 768px;
$sm-min: 576px;

// Main colors
$white-bg: #fff;
$primary-50: #f4f7fb;
$primary-100: #e9eef5;
$primary-200: #cddcea;
$primary-300: #a1bed8;
$primary-400: #6f9cc1;
$primary-500: #4d80aa;
$primary-600: #3a668f;
$primary-700: #305274;
$primary-800: #2b4661;
$primary-900: #2b4158;
$primary-950: #1b2736;

// Other colors
$orange-dark: #c16c41;
$orange-extra: #fbb997;
$orange-light: #feefe7;
$black: #3d3734;
$gray-content: #b1afae;
$gray-extra: #e4e4e4;
$gray-line: #ecebeb;
$gray-bg: #f7f7f7;
$white: #ecebeb;
$green: #60b286;
$green-light: #e8f3ed;
$red: #ed2424;
$red-light: #fdeceb;
$modal-bg: rgba(#383736, 0.7);

//Fonts
$Outfit_Bold: 'Outfit-Bold';
$Outfit_SemiBold: 'Outfit-SemiBold';
$Outfit_Medium: 'Outfit-Medium';
$Outfit_Regular: 'Outfit-Regular';
$Outfit_Light: 'Outfit-Light';

// Transition
$transition: all ease-in-out 0.3s;
$show: cubic-bezier(0, 0.55, 0.45, 1);
