.menu {
  position: fixed;
  z-index: 999;
  transform: translateX(-100%);
  transition: $transition;
  background-color: $white-bg;
  width: 100%;
  height: calc(100vh - 44px);
  top: 44px;
  right: 0;
  bottom: 0;
  left: 0;
  @include flex(center, center);
  &.active {
    transform: translateX(0);
  }
  &__list {
    display: flex;
    flex-direction: column;
    margin-top: -50px;
  }
  &__list-item {
    font-size: 24px;
    line-height: 28px;
    font-family: $Outfit_Bold, sans-serif;
    color: $primary-950;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    transition: $transition;
    &:hover {
      color: $primary-500;
    }
  }
  &__list-item:not(:first-child) {
    margin-top: 48px;
  }

  @include desktop-sm {
    width: fit-content;
    height: auto;
    position: static;
    background-color: transparent;
    transform: translateX(0);
    &__list {
      flex-direction: row;
      margin-top: 0;
    }
    &__list-item {
      font-size: 18px;
      line-height: 26px;
      font-family: $Outfit_Regular, sans-serif;
      margin-left: 40px;
    }
    &__list-item:not(:first-child) {
      margin-top: 0;
    }
  }
}

.burger__button {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 24px;
  height: 11.5px;
  cursor: pointer;
  span {
    width: 100%;
    height: 1.5px;
    background-color: $primary-950;
    transition: $transition;
  }
  &.active {
    span:first-child {
      transform: translateY(5px) rotate(45deg);
    }
    span:last-child {
      transform: translateY(-5px) rotate(-45deg);
    }
  }

  @include desktop-sm {
    display: none;
  }
}
